<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";

export default {
  page: {
    title: "Historiques des vidanges",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Paiements Location",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Historique",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "client", sortable: true, label: "Client" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "proprio", sortable: true, label: "Propriétaire" },
        { key: "telProprio", sortable: true, label: "Téléphone" },

        { key: "vehicule", sortable: true, label: "Vehicule" },
        { key: "montant", sortable: true, label: "Montant" },
        { key: "moyen", sortable: true, label: "Moyen" },
        { key: "date", sortable: true, label: "Date paiement" },
        { key: "statut", sortable: true, label: "Statut" },
        { key: "etat", sortable: true, label: "Etat" },
      ],
      transactionData: [],
      transactionDataL: [],
      total: 0,
      echecs: 0,
      reussies: 0,
      editorData: "",
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDataLocation: [],
      recherche: "",
      parBon: 500,
      totalAbonneInactif:0,
      totalAutoPartage:0,
      totalLocation:0
    };
  },

  async mounted() {
    const transactions = await apiRequest(
      "GET",
      "admin/rmo/transaction",
      undefined,
      false
    );
    if (transactions && transactions.data) {
  
      this.totalValue=transactions.data.total
     
      this.total = transactions.data.total ? transactions.data.total : 0;
      this.echecs = transactions.data.echecs ? transactions.data.echecs : 0;
      this.reussies = transactions.data.reussies
        ? transactions.data.reussies
        : 0;

      transactions.data.transactions.forEach((transaction) => {
        if (transaction.vehicule.user != 0) {
          this.transactionData.push({
            client:
              transaction.client.personne.lastname +
              " " +
              transaction.client.personne.firstname,
            tel:
              transaction.client.personne.indicatifTel +
              "" +
              transaction.client.personne.tel,
            vehicule:
              transaction.vehicule.marque + " " + transaction.vehicule.model,
            date: new Date(transaction.datePaiement).toLocaleString("fr-FR"),
            montant: transaction.montant,
            proprio :transaction.information_contact_nom,
            telProprio :transaction.information_contact_phone,
            moyen: transaction.moyen,
            statut: transaction.statut == "PAYER" ? "PAYÉ" : transaction.statut,
            etat: transaction.etat,
          });
        } else {
          this.transactionDataL.push({
            client:
              transaction.client.personne.lastname +
              " " +
              transaction.client.personne.firstname,
            tel:
              transaction.client.personne.indicatifTel +
              "" +
              transaction.client.personne.tel,
            vehicule:
              transaction.vehicule.marque + " " + transaction.vehicule.model,
            date: new Date(transaction.datePaiement).toLocaleString("fr-FR"),
            montant: transaction.montant,
            moyen: transaction.moyen,
            statut: transaction.statut == "PAYER" ? "PAYÉ" : transaction.statut,
            etat: transaction.etat,
            proprio :transaction.information_contact_nom,
            telProprio :transaction.information_contact_phone,
          });
        }
      });
this.totalAutoPartage=this.transactionData.length;
this.totalLocation= this.transactionDataL.length;
    }
  },

  methods: {


    async actif(page, limit) {
      //Chargement des données

      const transactions = await apiRequest(
        "GET",
        "admin/rmo/transaction?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (transactions && transactions.data) {
      this.total = transactions.data.total ? transactions.data.total : 0;
      this.echecs = transactions.data.echecs ? transactions.data.echecs : 0;
      this.reussies = transactions.data.reussies
        ? transactions.data.reussies
        : 0;

      const TransactListTable = transactions.data.transactions.map(
        (transaction) => {
          return {
            client:
              transaction.client.personne.lastname +
              " " +
              transaction.client.personne.firstname,
            tel:
              transaction.client.personne.indicatifTel +
              "" +
              transaction.client.personne.tel,
            vehicule:
              transaction.vehicule.marque + " " + transaction.vehicule.model,
            date: new Date(transaction.datePaiement).toLocaleString("fr-FR"),
            montant: transaction.montant,
            moyen: transaction.moyen,
            statut: transaction.statut=="PAYER"?"PAYÉ":transaction.statut,
            etat:transaction.etat,
          };
        }
      );
      this.newDataLocation = TransactListTable;
      this.userChargeLoader = false;
    }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async userSearch() {
     
      this.userChargeLoader = true;
      const transactions = await apiRequest(
        "GET",
        "admin/rmo/transaction-recherche?mots=" + this.filter,

        undefined,
        false
      );
      if (transactions && transactions.data) {
      this.total = transactions.data.total ? transactions.data.total : 0;
      this.echecs = transactions.data.echecs ? transactions.data.echecs : 0;
      this.reussies = transactions.data.reussies
        ? transactions.data.reussies
        : 0;

      const TransactListTable = transactions.data.transactions.map(
        (transaction) => {
          return {
            client:
              transaction.client.personne.lastname +
              " " +
              transaction.client.personne.firstname,
            tel:
              transaction.client.personne.indicatifTel +
              "" +
              transaction.client.personne.tel,
            vehicule:
              transaction.vehicule.marque + " " + transaction.vehicule.model,
            date: new Date(transaction.datePaiement).toLocaleString("fr-FR"),
            montant: transaction.montant,
            moyen: transaction.moyen,
            statut: transaction.statut=="PAYER"?"PAYÉ":transaction.statut,
            etat:transaction.etat,
          };
        }
      );
      this.newDataLocation = TransactListTable;
      this.userChargeLoader = false;
    }
      },
      decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parBon;
      this.endValue -= this.parBon;
     
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;
     
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;
     
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;
     
      this.actif(this.startValue, this.endValue);
    }








  },
};
</script>
    
    <template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
         
          <div class="card-body">
          
            <div class="row d-flex justify-content-start">
              <div class="col-md-2">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-primary"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-5">Total de transactions</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ total }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-2">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-info"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Total de transactions réussies</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ reussies }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-2">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-warning"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Total de transactions échouées</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ echecs }}</h1>
                  </div>
                </b-card>
              </div>
              <!-- <div class="col-md-6">
                <b-card
                  header-class="bg-transparent border-success"
                  class="border border-success"
                >
                  <div>
                    <ul>
                      <li>
                        <span
                          class="text-uppercase"
                          style="color: black; font-weight: bold"
                          >PAYÉ :</span
                        >
                        Transaction réussie
                      </li>
                      <li>
                        <span
                          class="text-uppercase"
                          style="color: black; font-weight: bold"
                          >Failed / ERROR :</span
                        >
                        Solde insuffisant ou transaction rejetée ou numéro
                        invalide
                      </li>
                    </ul>
                  </div>
                  <p>
                    Le statut
                    <span
                      class="text-uppercase"
                      style="color: black; font-weight: bold"
                      >Failed</span
                    >
                    ne signifie pas de manière systématique que la transaction a
                    échoué. Cela voudrait simplement dire que la dernière valeur
                    enregistrée du statut suite à la vérification est
                    <span
                      class="text-uppercase"
                      style="color: black; font-weight: bold"
                      >failed</span
                    >
                  </p>
                </b-card>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="transactionData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
            <div class="row d-flex justify-content-between align-items-center p-2">
              <h4 class="card-title mt-4">
              <p>Total d'abonnés : {{ totalAutoPartage }}</p>
            </h4>
            <!--   <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
         
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == parBon"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition> -->
            </div>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Autopartage</a>
                </template>
                <div class="row d-flex align-items-center justify-content-between mt-4">
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="filter"
                        @keyup.enter="userSearch"
                      />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="transactionData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >

                  <template v-slot:cell(tel)="row" style="text-align: center">
                  <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                      </template>

                      <template v-slot:cell(telProprio)="row" style="text-align: center">
                  <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                      </template>
                  </b-table>
         
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalAutoPartage"
                          :per-page="perPage"
                          :rows="transactionData"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Location</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="transactionDataL"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >

                  <template v-slot:cell(tel)="row" style="text-align: center">
                  <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                      </template>

                      <template v-slot:cell(telProprio)="row" style="text-align: center">
                  <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                      </template>
                  </b-table>
                  
                </div>
                <div class="row">
                  <div class="col">
                  <!--   <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
            
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == parBon"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition> -->
              <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalLocation"
                          :per-page="perPage"
                          :rows="transactionDataL"
                        ></b-pagination>
                      </ul>
                    </div>
            
            </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
    
<style>
  thead {
background: #3AAA35;
}

.input {
right: 13px;
position: absolute;
display: flex;
justify-content: center;
width: 200px;
height: 35px;
bottom: 0;
flex: 60%;
margin-bottom: 5px;
box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
background: #ffffff;
}

.input input {
padding: 3px 3px 3px 8px;
position: absolute;
top: 0;
width: 100%;
height: 100%;
border: none;
outline: none;
font-size: 18px;
color: #495057;
font-size: 1rem;
line-height: 1.5;
font-weight: 500;
}
.input input:focus {
border: 3px solid #d6d2f8;
}
.inputCol {
position: relative;
}
.page-item.active .page-link {
z-index: 3;
color: #fff;
background-color: #252b3b;
border-color: #252b3b;
}

.nav-tabs .nav-link {
background: #fadddd;
transition: all 0.5s;
}

.nav-tabs .nav-link a {
color: rgba(44, 56, 74, 0.95);
}
.nav-tabs-custom .nav-item .nav-link.active a {
color: blue;
font-weight: 600 !important;
vertical-align: middle;
}
.nav-tabs-custom .nav-item .nav-link.active {
font-weight: 600;
background: #3AAA35;
border-bottom: 1px solid #fff;
}
.nav-tabs-custom .nav-item .nav-link::after {
border-bottom: 1px solid #fff;
}

.nav-tabs .nav-link {
   background: #dc143c;
   transition: all .5s;
 } 
ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
}



.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #252b3b;
    border-color: #252b3b;
}
</style>